import replaceString from 'react-string-replace';
import { Translations } from '../../interfaces/translations';
import { ReactElement } from 'react';

export function getTranslator(
  templates: Translations,
  warnOnMissingTranslation: boolean = true,
) {
  /**
   * The translator function looks up a given phrase in the templates provided. If it is missing it will use the
   * provided phrase as the translated value. If the translated template contains {{strings}} then it will treat those
   * as template replacement variables which should be provided to the translator function in the form of an object
   * with a key matching the name of the variable ("strings" in this case) and the value with the value that should be
   * replaced in the place of the variable. The replacement can be a string or a React element. If the phrase contains
   * a variable and no replacement is provided, the variable will be removed and a console.error will be emitted
   * indicating the missing variable name along with the phrase it was found in.
   *
   * This function should ALWAYS return an array; this can be an array containing one or more
   * strings, or a mixture of strings and JSX (React elements).
   */
  return (
    phrase: string,
    replacements = {},
  ): Array<string | ReactElement<any>> => {
    if (phrase === '') {
      return [''];
    }
    const disableWarning = 'disableWarning';
    const templateMissingPhrase = templates[phrase] === undefined;
    // Allow translator calls without warnings for hacky Boulder reasons. Providing a replacement
    // of disableWarning: true will make it not complain if the translation doesn't exist.
    if (
      warnOnMissingTranslation &&
      templateMissingPhrase &&
      !replacements[disableWarning]
    ) {
      // tslint:disable-next-line:no-console
      console.warn(`Missing translation: '${phrase}'`);
    }

    const tagMatch = RegExp('{{([^}]*)}}', 'g');
    const phraseTemplate = !templateMissingPhrase ? templates[phrase] : phrase;
    let replacedText = phraseTemplate;
    let matchResult: RegExpExecArray | null;

    do {
      matchResult = tagMatch.exec(phraseTemplate);
      if (matchResult !== null) {
        const match = matchResult[1]; // Just the word, no curly brackets
        const pattern = `{{${match}}}`;
        let replace;

        let replaceCandidate: string | (() => string) =
          replacements[match] ||
          (() => {
            // tslint:disable-next-line:no-console
            console.error(
              `Missing '{{${match}}}' substitution. Phrase is "${phraseTemplate}"`,
            );
            return '';
          });

        // If a {{}} is provided, it will be translated to an empty string
        if (match === '') {
          replaceCandidate = () => '';
        }

        replace = replaceCandidate;
        if (typeof replaceCandidate !== 'function') {
          replace = () => replaceCandidate;
        }
        replacedText = replaceString(replacedText, pattern, replace);
      }
    } while (matchResult !== null);

    // We want to return an array all the time.
    if (!Array.isArray(replacedText)) {
      return [replacedText];
    }

    return replacedText;
  };
}

export default getTranslator;
