import { connect } from 'react-redux';
import PreferencesForm from 'components/forms/PreferencesForm.js';
import { submitPreferencesForm } from 'actionCreators';
import hash from 'object-hash';
import PreferenceModel from 'models/UserPreferenceModel';
import moment from 'moment/moment';
import { preferenceErrorMessages } from '../../components/notifications/preferenceErrorMessages';

const mapStateToProps = ({
  user,
  language,
  deliveryTypes,
  machine,
  launchDarkly,
}) => {
  let preferences = {
    email: user.email || '',
    sms: user.phone_sms || '',
    voice: user.pending_voice || user.phone_voice || '',
    pending_voice: user.pending_voice || '',
    limitStart: user.sms_do_not_contact_before || '08:00',
    limitEnd: user.sms_do_not_contact_after || '20:00',
    emailOptIn: user.email_opt_in || false,
    smsOptIn: user.sms_opt_in || false,
    voiceOptIn: user.voice_opt_in || false,
    limitHoursOptIn:
      user.sms_do_not_contact_before !== null ||
      user.sms_do_not_contact_after !== null,
    languagePreference: user.language_preference || '',
    isValidEmail: user.is_valid_email,
    validationMessages: user.validationMessages,
    code: user.code || null,
  };
  const formatter = (time) => moment(time, 'HH:mm:ss').format('h:mm A');

  return {
    machine,
    deliveryTypes,
    availableLanguages: language.availableLanguages,
    user: preferences,
    voterId: user.voter_id,
    isConfirmed: user.is_confirmed,
    key: hash(preferences),
    formatter,
  };
};

export const submitHandler = (preferences, bag, dispatch) => {
  const { isConfirmed, user } = bag.props;
  const preferenceModel = new PreferenceModel(user, preferences);
  const json = preferenceModel.toJSON();

  // In the rare, and hopefully soon-to-be impossible case of DND times being the same, this will
  // make the voter preferences form fix the problem.
  if (preferences.limitStart === preferences.limitEnd) {
    json.sms_do_not_contact_before = '08:00';
    json.voice_do_not_contact_before = '08:00';
    json.email_do_not_contact_before = '08:00';
    json.sms_do_not_contact_after = '20:00';
    json.voice_do_not_contact_after = '20:00';
    json.email_do_not_contact_after = '20:00';
  }

  json.language_preference = preferences.languagePreference;

  if (preferences.isValidEmail === false) {
    bag.setErrors({ email: preferenceErrorMessages.email.invalidEmail });
    return;
  }
  if (Object.keys(json).length !== 0) {
    dispatch(submitPreferencesForm(json, isConfirmed));
  }
};

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (preferences, bag) => {
    submitHandler(preferences, bag, dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesForm);
