import { User } from '../interfaces/user';

const initialUser: Readonly<User> = {
  address: '',
  birthdate: '',
  birthyear: null,
  city: '',
  county: '',
  created_timestamp: '',
  email: '',
  email_do_not_contact_after: '',
  email_do_not_contact_before: '',
  email_opt_in: false,
  firstname: '',
  is_active: false,
  language_preference: '',
  lastname: '',
  middlename: '',
  phone: '',
  phone_sms: '',
  phone_voice: '',
  pending_voice: '',
  precinct: '',
  provided_voter_id: '',
  registration_id: '',
  sms_do_not_contact_after: '',
  sms_do_not_contact_before: '',
  sms_opt_in: false,
  state: '',
  suffix: '',
  voice_do_not_contact_after: '',
  voice_do_not_contact_before: '',
  voice_opt_in: false,
  voter_id: 0,
  zipcode: '',
  is_confirmed: false,
  is_valid_email: false,
  sms_status: '',
  idle_timer: null,
};

export default initialUser;
