import { preferenceErrorMessageNew } from 'util/error/preferenceErrorMessage';

export const addMessages = (type, messages, action, prepend = false) => {
  const result = { ...action, alerts: { ...action.alerts } };

  if (!(type in result.alerts)) {
    result.alerts[type] = {
      type,
      icon: type,
      messages: [],
    };
  }

  // put new messages first when prepending
  result.alerts[type].messages = prepend
    ? [...messages, ...result.alerts[type].messages]
    : [...result.alerts[type].messages, ...messages];

  return result;
};

const shouldShowOptOutNotice = (request, response, isConfirmed) => {
  const madeOptOutRequest =
    request.email_opt_in === false ||
    request.voice_opt_in === false ||
    request.sms_opt_in === false;
  const newlyConfirmedUser =
    isConfirmed === false && response.is_confirmed === true;
  const optedOutOfEverything =
    !response.email_opt_in && !response.voice_opt_in && !response.sms_opt_in;

  return (
    (madeOptOutRequest && optedOutOfEverything) ||
    (newlyConfirmedUser && optedOutOfEverything)
  );
};

const shouldShowSMSCallToAction = (request, response) => {
  const optedInAndNotConfirmed =
    response.sms_opt_in === true && response.sms_status !== true;
  const smsChangedOrOptedIn =
    request.hasOwnProperty('phone_sms') || request.hasOwnProperty('sms_opt_in');

  return optedInAndNotConfirmed && smsChangedOrOptedIn;
};

const shouldShowEmailCallToAction = (request, response) => {
  const optedInAndNotValidated =
    response.email_opt_in === true && response.is_valid_email === null;
  const emailChangedOrOptedIn =
    request.hasOwnProperty('email') || request.hasOwnProperty('email_opt_in');

  return optedInAndNotValidated && emailChangedOrOptedIn;
};

const shouldShowVoiceCallToAction = (request, response) => {
  const voiceChangedOrOptedIn =
    request.hasOwnProperty('phone_voice') ||
    request.hasOwnProperty('voice_opt_in');

  return response.voice_opt_in === true && voiceChangedOrOptedIn;
};

const preferencesFormAlertBuilder = (request, response, params = {}) => {
  const options = {
    ok: true,
    status: 200,
    isConfirmed: true,
    code: 'YES',
    ...params,
  };
  let shouldAddImportantNotice = false;
  let action = {
    type: 'UPDATE_GLOBAL_ALERTS',
    alerts: {},
  };
  if (!options.ok) {
    action = addMessages('error', preferenceErrorMessageNew(response), action);
  }

  if (options.status === 422) {
    action = addMessages(
      'notice',
      [
        {
          text: 'Your information has not been updated!',
          important: false,
        },
      ],
      action,
    );
  }

  if (options.status === 200) {
    action = addMessages(
      'success',
      [
        {
          text: 'Your information has been updated.',
          important: false,
        },
      ],
      action,
    );

    if (shouldShowOptOutNotice(request, response, options.isConfirmed)) {
      action = addMessages(
        'notice',
        [
          {
            text: 'You have chosen to opt-out of all notifications.',
            important: false,
          },
        ],
        action,
      );
    }
    if (shouldShowSMSCallToAction(request, response)) {
      shouldAddImportantNotice = true;
      action = addMessages(
        'notice',
        [
          {
            text: 'Updates via SMS message: You will receive a welcome message soon, please reply or send "YES" to 41575 to activate your Text Message number.',
            important: false,
            code: options.code,
          },
        ],
        action,
      );
    }

    if (shouldShowEmailCallToAction(request, response)) {
      shouldAddImportantNotice = true;
      action = addMessages(
        'notice',
        [
          {
            text: 'Updates via email: You will receive a welcome email soon, please check your spam folder and add donotreply@ballottrax.net to your contact list.',
            important: false,
          },
        ],
        action,
      );
    }

    if (shouldShowVoiceCallToAction(request, response)) {
      shouldAddImportantNotice = true;
      action = addMessages(
        'notice',
        [
          {
            text: 'Please call {{from_voice}} to confirm your intent to receive ballot status updates via voice call. Updates will begin once your phone number is confirmed.',
            important: false,
            from_voice: '(720) 225-0492',
          },
        ],
        action,
      );
    }

    if (shouldAddImportantNotice) {
      action = addMessages(
        'notice',
        [
          {
            text: 'To ensure you receive updates in a timely manner, please check the following:',
            important: true,
          },
        ],
        action,
        true,
      );
    }
  }

  return action;
};

export default preferencesFormAlertBuilder;
