export const messages = {
  email: {
    optIn: [
      'You will receive ballot updates via Email',
      'If you have not received an email update, please check your spam folder and add donotreply@ballottrax.net to your contact list',
    ],
    optOut: ['You will NOT receive ballot updates via Email'],
  },
  sms: {
    optIn: [
      'You will receive ballot updates via Text Message',
      'Please note that standard messaging fees will apply',
    ],
    optOut: ['You will NOT receive ballot updates via Text Message'],
  },
  voice: {
    // we don't want to show optIn here, because voice calls needs to be confirmed first
    optIn: [''],
    optOut: ['You will NOT receive ballot updates via Voice Call'],
  },
  limitHours: {
    optIn: [
      'You will only be contacted between the hours of {{early}} & {{late}}',
    ],
    optOut: ['You will be contacted as soon as messages are available.'],
  },
};
